/* eslint-disable camelcase */
import { TeaserLinkUtmParams } from '@hubcms/domain-tracking';

export type TeaserLinkUtmData = Pick<
  TeaserLinkUtmParams,
  'utm_campaign' | 'utm_content' | 'utm_medium' | 'utm_source' | 'utm_term'
>;

export function createTeaserLinkUtmParams(data: TeaserLinkUtmData): Partial<TeaserLinkUtmParams> {
  const teaserLinkUtmParams = {
    utm_campaign: data.utm_campaign,
    utm_content: data.utm_content,
    utm_medium: data.utm_medium,
    utm_source: data.utm_source,
    utm_term: data.utm_term,
  };
  return Object.fromEntries(Object.entries(teaserLinkUtmParams).filter(([_, value]) => value !== ''));
}
