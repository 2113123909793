import { TrackingData } from '@hubcms/domain-tracking';

import type { Ga4SelectContentData } from './create-select-content-event';
import { isGa4SelectContentData } from './is-select-content-data';

export function mapGa4SelectContentData(data?: TrackingData | null): Ga4SelectContentData | null {
  if (!isGa4SelectContentData(data)) {
    return null;
  }

  return {
    articleauthor: data.articleauthor ?? null,
    articlebodycharactercount: data.articlebodycharactercount ?? null,
    articleid: data.articleid,
    articleimagecount: data.articleimagecount ?? null,
    articlelayout: data.articlelayout ?? null,
    articlepublicationdateinutc: data.articlepublicationdateinutc ?? null,
    articlesource: data.articlesource ?? null,
    articletaglist: data.articletaglist ?? null,
    articletype: data.articletype ?? null,
    articlewordcount: data.articlewordcount ?? null,
    clickitemblock: data.clickitemblock ?? null,
    clickitemname: data.clickitemname,
    clickitemposition: data.clickitemposition,
    clickitemurl: data.clickitemurl,
    pagesecuretype: data.pagesecuretype,
    recommendationsource: data.recommendationsource ?? null,
  };
}
